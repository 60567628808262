<header>
        <div class="image-header-sonstige" style="background-image: url('assets/img/werkstatt_außen.jpg')"></div>
        <div class="row" style="margin-right: 0px">
            <div class=".col-xl-10">
                <a routerLink="/home"><img class="fullBg" src="assets/img/waller.png"></a>
            </div>
            <!--
              <div class=".col-xl-2">
                <div class="name">
                    <h1>Robert Waller</h1>
                    <h2>Automobile</h2>
                </div>
            </div>
            -->
        </div>
        <button class="menu-anchor" (click)="toggleDisplay()">
            <mat-icon>menu</mat-icon>
        </button>
        <menu class="menu" *ngIf="isShow" [@EnterLeave]="'flyIn'">
            <ul>
                <li><a routerLink="/home">HOME</a></li>
                <li><a routerLink="/leistungen">LEISTUNGEN</a></li>
                <li><a routerLink ="/fahrzeugbestand">FAHRZEUGBESTAND</a></li>
                <li><a routerLink="/contact">KONTAKT</a></li>
                <li><a routerLink="/datenschutz">Datenschutz</a></li>
                <li><a routerLink="/impressum">Impressum</a></li>
            </ul>
        </menu>
</header>