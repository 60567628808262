import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leistungen',
  templateUrl: './leistungen.component.html',
  styleUrls: ['./leistungen.component.scss']
})
export class LeistungenComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller) { }

  public isCollapsedWerkstatt = false;
  public isCollapsedReifen = false;
  public isCollapsedGebrauchtwagen = false;

  showShortDesciption = false

  alterDescriptionText() {
    this.showShortDesciption = !this.showShortDesciption
 }

 public onClick(elementId: string): void { 
  this.viewportScroller.scrollToAnchor(elementId);
}

  ngOnInit(): void {
  }

}
