<app-header-contact></app-header-contact>
<div class="contact">
    <div class="row">
        <div class=".col-xl-12">
            <h1>Ihr Partner in Tennenbronn für Automobile</h1>
        </div>
    <div class="container">
      <div class="row">
        <div class=".col-xl-12 text-center">
          <section id="contact-form-anchor" >
                  <h2>Sie haben Fragen oder wollen einen Termin vereinbaren?</h2>
                  <strong><h2>Bitte rufen Sie uns unter <a href="tel:+497729/8484">07729 / 8484</a> an.</h2></strong>
          </section>
      </div>
    </div>
    </div>
                <div class=".col-xl-12">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10639.43063267099!2d8.349677258691406!3d48.19009398052093!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb5da984a2e839048!2sGeorg%20Waller!5e0!3m2!1sde!2sde!4v1599834976343!5m2!1sde!2sde" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="true" aria-hidden="false" tabindex="0"></iframe>
                </div>
    </div>
</div>
