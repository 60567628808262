import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public disableBtn = false;
  public showMsg = false;
  public showError = false;

  public contactForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl('', Validators.required),
    phone: new FormControl(''),
    message: new FormControl(''),
    dsgvo: new FormControl('', Validators.required)
  });

  constructor(private http: HttpClient) { }
  
  ngOnInit(): void {
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.disableBtn = true;
      let url = "http://localhost:9090/sozialberatungsnetz-website-angular/src/assets/mail.php";
      if (environment.production) {
        url = "/assets/mail.php";
      }
      this.http.post(url, this.contactForm.value)
        .subscribe(
          (data) => {
            this.showMsg = true;
            this.showError = false;
            console.log("POST call successful value returned in body", data);
          },
          response => {
            this.showError = true;
            this.disableBtn = false;
            console.log("POST call in error", response);
          },
          () => {
            console.log("The POST observable is now completed.");
          });

    }
  }

}
