<app-header-sonstige></app-header-sonstige>
<div class="container leistungen_überschrift">
  <div class="row">
    <div class="col-md-12">
      <h2>Unsere Leistungen</h2>
    </div>
  </div>
</div>
<div class="container header_leistungen">
  <div class="row">
    <div class="col-md-4">
      <ul>
        <a (click)="onClick('werkstattservice')"> <li>Werkstattservice</li></a>
        <a (click)="onClick('gebrauchtwagen')"><li>Gebrauchtwagen</li></a>
        <a (click)="onClick('bringservice')"><li>Hol-& Bringservice</li></a>
      </ul>
    </div>
    <div class="col-md-4">
      <ul>
        <a (click)="onClick('reifenservice')"><li>Reifenservice</li></a>
        <a (click)="onClick('felgenservice')"><li>Felgenservice</li></a>
        <a (click)="onClick('kaufberatung')"><li>Professionelle Kaufberatung</li></a>
      </ul>
    </div>
    <div class="col-md-4">
      <ul>
        <a (click)="onClick('unfall')"><li>Unfallinstandsetzung</li></a>
        <a (click)="onClick('fahrzeugaufbereitung')"><li>Fahrzeugaufbereitung</li></a>
        <a (click)="onClick('vielesMehr')"><li>und vieles mehr...</li></a>
      </ul>
    </div>
  </div>
</div>
<div class="container werkstattservice" id="werkstattservice">
  <h2 class="ueberschrift-leistungen">Werkstattservice</h2>
  <div class="row">
    <div class="col-md-6">
      <div class="carousel">
        <ngb-carousel [interval]="5000">
          <ng-template ngbSlide>
            <div
              class="picsum-img-wrapper"
              style="
                background-image: url('../../assets/img/werkstattservice.jpg');
              "
            ></div>
            <div class="carousel-caption"></div>
          </ng-template>
          <ng-template ngbSlide>
            <div
              class="picsum-img-wrapper"
              style="
                background-image: url('../../assets/img/mercedes_alt.jpg');
              "
            ></div>
            <div class="carousel-caption"></div>
          </ng-template>
          <ng-template ngbSlide>
            <div
              class="picsum-img-wrapper"
              style="
                background-image: url('../../assets/img/werkzeug.jpg');
              "
            ></div>
          </ng-template>
          <ng-template ngbSlide>
            <div
              class="picsum-img-wrapper"
              style="
                background-image: url('../../assets/img/motor.jpg');
              "
            ></div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
    <div class="col-md-6 leistung_beschreibung">
      <p>
        Als freier Kfz-Meisterbetrieb bieten wir markenunabhängige
        Serviceleistungen rund um Ihren Pkw oder Transporter. Vom Fahrzeug-Check
        über Durchsicht bis zur Reparatur – Auspuff, Bremsen, HU/AU, Inspektion
        bis Zahnriemen
      </p>
      <p>
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="isCollapsedWerkstatt = !isCollapsedWerkstatt"
          [attr.aria-expanded]="isCollapsedWerkstatt"
          aria-controls="collapseWerkstattservice"
        >
          mehr...
        </button>
      </p>
      <div id="collapseWerkstattservice" [ngbCollapse]="!isCollapsedWerkstatt">
        <div class="card">
          <div class="card-body">
            <h2>Warten, pflegen, reparieren</h2>
            <p>
              Ob Inspektionen, verschlissene Bremsen, Auspuff- oder
              Motorgeräusche, aufleuchtende Kontrolllampen, Getriebeprobleme –
              wir helfen Ihnen gerne weiter. Die Kfz-Meisterwerkstatt Waller
              bietet Ihnen qualifizierte und fachgerechte Reparaturen mit
              Ori-ginalteilen. Unser Auto-Service erhält den optimalen Zustand
              Ihres Fahrzeuges – oder stellt ihn wieder her, in fachmännischer
              Perfektion, bei möglichst geringem Aufwand.
            </p>
            <br />
            <ul>
              <li>
                Inspektionen für alle Marken entsprechend den Herstellervorgaben
              </li>
              <li>
                Fehlerdiagnose mit Diagnosetechnik, Diagnosezentrum –für alle
                Fahrzeugmarken
              </li>
              <li>Achsvermessung mit hochmoderner Vermessungstechnik</li>
              <li>Scheibenreparatur / Autoglasservice</li>
              <li>HU/AU (Dekra)</li>
              <li>TÜV-Vorkontrolle</li>
              <li>Fahrzeugaufbereitung</li>
              <li>
                Ersatz von Autoglas, wie Windschutzscheiben und Seitenscheiben
              </li>
              <li>Einsatz von Spezialwerkzeugen für alle Fahrzeugtypen</li>
              <li>Fahrzeugreparaturen, insbesondere auch Auspuff, Bremsen</li>
              <li>Klimaanlagen-Service</li>
              <li>Unfall: Karosserieinstandsetzung, Gutachtervermittlung</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container reifenservice" id="reifenservice">
  <h2 class="ueberschrift-leistungen">Reifenservice</h2>
  <div class="row">
    <div class="col-md-6">
      <div class="carousel">
        <ngb-carousel [interval]="5000">
          <ng-template ngbSlide>
            <div
              class="picsum-img-wrapper"
              style="
                background-image: url('../../assets/img/reifenservice_1.jpg');
              "
            ></div>
            <div class="carousel-caption"></div>
          </ng-template>
          <ng-template ngbSlide>
            <div
              class="picsum-img-wrapper"
              style="
                background-image: url('../../assets/img/reifenservice_2.jpg');
              "
            ></div>
            <div class="carousel-caption"></div>
          </ng-template>
          <ng-template ngbSlide>
            <div
              class="picsum-img-wrapper"
              style="
                background-image: url('../../assets/img/reifenservice_3.jpg');
              "
            ></div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
    <div class="col-md-6 leistung_beschreibung">
      <p>
        Reifenwechsel, Reifeneinlagerung oder der Kauf neuer Winter- oder
        Sommerreifen für individuelle Ansprüche.
      </p>
      <p>
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="isCollapsedReifen = !isCollapsedReifen"
          [attr.aria-expanded]="!isCollapsedReifen"
          aria-controls="collapseReifenservice"
        >
          mehr...
        </button>
      </p>
      <div id="collapseReifenservice" [ngbCollapse]="!isCollapsedReifen">
        <div class="card">
          <div class="card-body">
            <h2>Reifenwechsel, Reifeneinlagerung & Reifenkauf</h2>
            <p>
              Ist ein Reifen nicht mehr intakt, so können Probleme beim
              Beschleunigen, Bremsen, Fah-ren in eine Kurve (besonders bei
              nasser Fahrbahn) entstehen. Bei Temperaturen knapp unter 7°C
              verlieren Sommerreifen ihre geforderten Eigenschaften – das führt
              zu einem eingeschränkten Grip Verhalten des Reifens und kann sich
              in schwierigen Ver-kehrssituationen zur völligen
              Unkontrollierbarkeit des Fahrzeuges auswirken.
            </p>
            <br />
            <p>
              Deswegen wechseln Sie schon in den Herbstmonaten auf Winterreifen
              mit Ihrer speziellen Gummi-Mischung und nutzen Sie die positiven
              Eigenschaften für ein sicheres Fahren durch den Winter.
            </p>
            <br />
            <ul>
              <li>Grip auf Schnee, Matsch und Eis</li>
              <li>Rutschsicherheit</li>
              <li>Spurgenauigkeit</li>
              <li>kürzere Bremswege</li>
              <li>höhere Elastizität</li>
              <li>Flexibilität</li>
            </ul>
            <br />
            <p>
              Die Kfz-Meisterwerkstatt Waller wechselt schnell und zuverlässig
              Ihre Reifen von Sommer-reifen auf Winterreifen oder umgedreht. Das
              fachgerechte Einlagern Ihres nicht benötigten Reifensatzes können
              wir ebenso für Sie übernehmen!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container gebrauchtwagen" id="gebrauchtwagen">
  <h2 class="ueberschrift-leistungen">Gebrauchtwagen</h2>
  <div class="row">
    <div class="col-md-6">
      <img class="gebrauchtwagen_bild" src="assets/img/gebrauchtwagen.jpg" />
    </div>
    <div class="col-md-6 leistung_beschreibung">
      <p>
        Wir bieten faire Preise und garantieren eine schnelle Abwicklung und
        zuverlässige Zahlung.
      </p>
      <p>
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="isCollapsedGebrauchtwagen = !isCollapsedGebrauchtwagen"
          [attr.aria-expanded]="!isCollapsedGebrauchtwagen"
          aria-controls="collapseGebrauchtwagen"
        >
          mehr...
        </button>
      </p>
      <div
        id="collapseGebrauchtwagen"
        [ngbCollapse]="!isCollapsedGebrauchtwagen"
      >
        <div class="card">
          <div class="card-body">
            <h2>Gebrauchtwagen Ankauf & Verkauf</h2>
            <p>
              Gebrauchtwagen in bester Qualität bei Ihrem Kfz-Meisterbetrieb
              Waller Wir führen einen seriösen Gebrauchtwagenankauf und
              Gebrauchtwagenverkauf durch. Bei uns werden alle Gebrauchtwagen
              einer gründlichen Untersuchung unterzogen. In unserem
              Meisterbetrieb wird jeder Wagen auf Herz und Nieren getestet.
              Unter anderem Bremsen, Beleuchtung, Kupplung und vieles mehr
              werden geprüft und gegebenenfalls repariert und fachmännisch
              in-standgesetzt. Wir möchten nicht nur Autos verkaufen, sondern
              Ihnen als unserem Kunden opti-male Leistung bieten.
            </p>
            <br />
            <h2>PKW Ankauf zu Top-Konditionen – schnell und seriös!</h2>
            <br />
            <h3>Gebrauchtwagen Ankauf und Unfallwagen Ankauf</h3>
            <p>
              Die Kfz-Meisterwerkstatt Waller in Tennenbronn garantiert Ihnen
              einen zuverlässigen, schnellen und reibungslosen Autoankauf zu
              besten Konditionen. Der Gebrauchtwagen Ankauf oder Unfall-wagen
              Ankauf durch uns erfolgt für Sie selbstverständlich ohne Garantie-
              oder Gewährleistungs-ansprüche.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container unfall" id="unfall">
  <h2 class="ueberschrift-leistungen">Unfallinstandsetzung</h2>
  <div class="row">
    <div class="col-md-6">
      <div class="carousel">
        <ngb-carousel [interval]="5000">
          <ng-template ngbSlide>
            <div
              class="picsum-img-wrapper"
              style="
                background-image: url('../../assets/img/unfall.jpg');
              "
            ></div>
            <div class="carousel-caption"></div>
          </ng-template>
          <ng-template ngbSlide>
            <div
              class="picsum-img-wrapper"
              style="
                background-image: url('../../assets/img/unfall_2.jpg');
              "
            ></div>
            <div class="carousel-caption"></div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
    <div class="col-md-6 leistung_beschreibung">
      <p>
        Eine kompetente Schadensabwicklung, eine schnelle und reibungslose
        Reparatur, Miet-/Leihwagen und hochwertige Lackier- und
        Reparatur-Techniken erwarten Sie bei der Kfz-Meisterwerkstatt Waller.
      </p>
      <p>
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="isCollapsedGebrauchtwagen = !isCollapsedGebrauchtwagen"
          [attr.aria-expanded]="!isCollapsedGebrauchtwagen"
          aria-controls="collapseGebrauchtwagen"
        >
          mehr...
        </button>
      </p>
      <div
        id="collapseGebrauchtwagen"
        [ngbCollapse]="!isCollapsedGebrauchtwagen"
      >
        <div class="card">
          <div class="card-body">
            <h2>Kompetente Schadensabwicklung bei einem Unfall</h2>
            <h3>
              Schnelle und reibungslose Reparatur, Miet-/Leihwagen und
              hochwertige Lackier- und Reparatur-Techniken
            </h3>
            <p>
              Ob kleiner oder großer Schaden an der Karosserie? Unsere
              Mitarbeiter vom Kfz-Meisterbetrieb Waller sind Ihre richtigen
              Ansprechpartner bei kleineren Dellen, Lackschäden bis zu
              schwerwiegenden Unfallschäden – mit modernsten
              Instand-setzungstechniken, Ganz- & Teillackierungen aber auch für
              eine professionelle Dellenentfernung.
            </p>
            <h2>PKW Ankauf zu Top-Konditionen – schnell und seriös!</h2>
            <ul>
              <li>Gutachtervermittlung</li>
              <li>Karosseriearbeiten</li>
              <li>Unfallinstandsetzung</li>
              <li>Werkstattersatz- / Unfallersatzfahrzeug</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container felgen" id="felgenservice">
  <h2 class="ueberschrift-leistungen">Felgenservice</h2>
  <div class="row">
    <div class="col-md-6">
      <img class="felgenservice_bild" src="assets/img/felgenreparatur.jpg" />
    </div>
    <div class="col-md-6 leistung_beschreibung">
      <p>
        Alles rund um das Thema Felgen. Beratung & Auswahl von Felgen sämtlicher
        Premiumhersteller. Instandsetzung - Felgen reparieren, Felgen veredeln
        oder Felgen restaurieren:
      </p>
      <p>
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="isCollapsedGebrauchtwagen = !isCollapsedGebrauchtwagen"
          [attr.aria-expanded]="!isCollapsedGebrauchtwagen"
          aria-controls="collapseGebrauchtwagen"
        >
          mehr...
        </button>
      </p>
      <div
        id="collapseGebrauchtwagen"
        [ngbCollapse]="!isCollapsedGebrauchtwagen"
      >
        <div class="card">
          <div class="card-body">
            <ul>
              <li>
                Höhenschlagreparatur, Reparatur glanzgedrehter Felgen,
                Pulverbeschichten oder Hochglanzverdichten – Ihr Anliegen ist
                beim Kfz-Betrieb Waller in besten Händen.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container fahrzeugaufbereitung" id="fahrzeugaufbereitung">
  <h2 class="ueberschrift-leistungen">Fahrzeugaufbereitung</h2>
  <div class="row">
    <div class="col-md-6">
      <img
        class="fahrzeugaufbereitung_bild"
        src="assets/img/fahrzeugaufbereitung.jpg"
      />
    </div>
    <div class="col-md-6 leistung_beschreibung">
      <p>
        Mit der Fahrzeugaufbereitung versetzen wir Ihr Fahrzeug optisch und
        hygienisch wieder in seinen Optimalzustand. Das geschieht durch
        Techniken der Reinigung, kleineren Reparaturen am Fahrzeug, der
        Beseitigung von Gerüchen und Korrekturen von Blech, Lack oder
        Kunststoffoberflächen.
      </p>
      <p>
    </div>
  </div>
</div>
<div class="container bringservice" id="bringservice">
  <h2 class="ueberschrift-leistungen">Hol-& Bringservice</h2>
  <div class="row">
    <div class="col-md-6">
      <div class="carousel">
        <ngb-carousel [interval]="5000">
          <ng-template ngbSlide>
            <div
              class="picsum-img-wrapper"
              style="
                background-image: url('../../assets/img/ferrari_anhaenger.jpg');
              "
            ></div>
            <div class="carousel-caption"></div>
          </ng-template>
          <ng-template ngbSlide>
            <div
              class="picsum-img-wrapper"
              style="
                background-image: url('../../assets/img/anhänger_2.jpg');
              "
            ></div>
            <div class="carousel-caption"></div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
    <div class="col-md-6 leistung_beschreibung">
      <p>
        Hochmoderne Transport-Anhänger des Kfz-Betriebs Wallers gewährleisten
        einen sicheren und unscheinbaren geschlossenen Autotransport für Ihr
        wertvolles Fahrzeug. Wir garantieren die sichere Verladung durch den
        Einsatz einer Auffahrrampe und die fachgerechte Sicherung von A nach B.
      </p>
      <p>
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="isCollapsedGebrauchtwagen = !isCollapsedGebrauchtwagen"
          [attr.aria-expanded]="!isCollapsedGebrauchtwagen"
          aria-controls="collapseGebrauchtwagen"
        >
          mehr...
        </button>
      </p>
      <div
        id="collapseGebrauchtwagen"
        [ngbCollapse]="!isCollapsedGebrauchtwagen"
      >
        <div class="card">
          <div class="card-body">
            <ul>
              <li>
                Jedes Fahrzeug, ob Oldtimer oder überbreiter Super-Sportwagen ,–
                wir transportieren ihr Fahrzeug sicher, witterungsunabhängig und
                geschützt von den Blicken anderer an den Zielort oder bis vor
                Ihre Haustüre.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container kaufberatung" id="kaufberatung">
  <h2 class="ueberschrift-leistungen">Professionelle Kaufberatung</h2>
  <div class="row">
    <div class="col-md-6">
      <img class="picsum-img-wrapper" style="
      background-image: url('../../assets/img/kaufberatung.jpg');
    " />
    </div>
    <div class="col-md-6 leistung_beschreibung">
      <p>
        beim Kfz-Betrieb Waller beraten wir Sie gerne beim Kauf/Verkauf Ihres
        Fahrzeugs, egal ob Neu- oder Gebrauchtwagen. Wie helfen Ihnen das
        perfekte Auto für Sie zu finden. So sparen Sie Zeit, Geld und Nerven und
        können sich entspannt zurücklehnen.
      </p>
    </div>
  </div>
</div>
<div class="container vielesMehr" id="vielesMehr">
  <h2 class="ueberschrift-leistungen">...und vieles mehr</h2>
  <div class="row">
    <div class="col-md-6">
      <img class="vielesMehr_bild" src="assets/img/logo_waller.png"/>
    </div>
    <div class="col-md-6 leistung_beschreibung">
      <ul>
        <li>ordnungsgemäße Zulassung Ihres Fahrzeugs</li>
        <li>Reservierung und Besorgung ihres Wunschkennzeichens</li>
        <li>Gutachten erstellen lassen</li>
      </ul>
    </div>
  </div>
</div>
