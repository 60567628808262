import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './contact/contact.component';
import { DescriptionComponent } from './description/description.component';
import { HttpClientModule } from '@angular/common/http';
import { HeaderStartComponent } from './header-start/header-start.component';
import { HeaderContactComponent } from './header-contact/header-contact.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { HeaderSonstigeComponent } from './header-sonstige/header-sonstige.component';
import { LeistungenComponent } from './leistungen/leistungen.component';
import { FahrzeugbestandComponent } from './fahrzeugbestand/fahrzeugbestand.component';

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    DescriptionComponent,
    HeaderStartComponent,
    HeaderContactComponent,
    ImpressumComponent,
    DatenschutzComponent,
    HeaderSonstigeComponent,
    LeistungenComponent,
    FahrzeugbestandComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    MatIconModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
