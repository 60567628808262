<app-header-start></app-header-start>
<div class="container">
    <div class="beschreibung">
        <div class="row">
            <div class=".col-xl-12">
                <h1>Automobile Leidenschaft <br>
                    seit mehr als 25 Jahren
                </h1>
            </div>
            <div class="container">
                <div class="row">
                    <div class=".col-xl-6">
                        <p>Begeisterung und Professionalität – die Geschichte des Familienunternehmens Waller ist geprägt von beständigen Werten und einer über 25-jährigen Tradition.
                            Dies zeigt sich auch in den sehr persönlichen, wertschätzenden, ja freundschaftlichen Beziehungen zu unseren Kunden, auf deren individuellen Bedürfnisse und Wünsche wir bestmöglich eingehen. 
                            Begleiten Sie uns in eine faszinierende automobile Welt und besuchen Sie uns in Tennenbronn:<br>
                            Herzlich willkommen bei Waller Automobile!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
