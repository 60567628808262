import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-header-contact',
  templateUrl: './header-contact.component.html',
  styleUrls: ['./header-contact.component.scss'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.3s 300ms')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class HeaderContactComponent implements OnInit {

  constructor() { }

  isShow = false;
  
  toggleDisplay() {
    if(this.isShow == false) {
      this.isShow = true;
    } else {
      this.isShow = false
    }
  }

  ngOnInit(): void {
  }

}
