import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fahrzeugbestand',
  templateUrl: './fahrzeugbestand.component.html',
  styleUrls: ['./fahrzeugbestand.component.scss']
})
export class FahrzeugbestandComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
