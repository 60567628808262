import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact/contact.component'; 
import { DescriptionComponent } from './description/description.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { LeistungenComponent } from './leistungen/leistungen.component';
import { FahrzeugbestandComponent } from './fahrzeugbestand/fahrzeugbestand.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DescriptionComponent
  },
  {
    path: 'home',
    pathMatch: 'full',
    component: DescriptionComponent
  },
  {
    path: 'contact',
    pathMatch: 'full',
    component: ContactComponent
  },
  {
    path: 'impressum',
    pathMatch: 'full',
    component: ImpressumComponent
  },
  {
    path: 'datenschutz',
    pathMatch: 'full',
    component: DatenschutzComponent
  },
  {
    path: 'leistungen',
    pathMatch: 'full',
    component: LeistungenComponent
  },
  {
    path: 'fahrzeugbestand',
    pathMatch: 'full',
    component: FahrzeugbestandComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
