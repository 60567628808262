<router-outlet></router-outlet>

<footer>
    <div class="footer">
        <div class="row">
            <div class="container">
                <div class="anschrift">
                    <div class=".col-xl-12">
                        <h3>Robert Waller Automobile</h3>
                        <p>Am Unterschiltacher Weg 6</p>
                        <p>78144 Tennenbronn</p>
                        <br>
                        <br>
                        <p>Telefon: 07729 / 8484</p>
                        <p>E-Mail: autowaller@t-online.de</p>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="links">
                    <div class="row">
                        <div class=".col-xl-4">
                            <a routerLink="datenschutz"><p>Datenschutz</p></a>
                        </div>
                        <div class=".col-xl-4">
                            <a routerLink="impressum"><p>Impressum</p></a>
                        </div>
                        <div class=".col-xl-4">
                            <a routerLink="contact"><p>Kontakt</p></a>
                        </div>
                        <img class="footer-img" src="assets/img/footer.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>